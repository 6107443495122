<template>
    <div>
        <h2 class="cs-title">
            <ColorSplit :data="data.title" />
        </h2>
        <div class="action-area">
            <div class="text-content">
                <h3 class="cs-sub-title" v-html="data.subTitle"></h3>
                <span class="cs-text" v-html="data.text"></span>
            </div>
            <div class="button-area">
                <div class="cs-checkbox" v-if="customize">
                    <span>
                        <input type="checkbox" id="functional" name="functional" disabled v-model="functionalValue" v-bind:id="'functionalValue'">
                        <label for="functional"> {{data.functionalLabel}}</label>
                    </span>
                    <br>

                    <span>
                        <input type="checkbox" id="analytical" name="analytical" v-model="analyticsValue" v-bind:id="'analyticsValue'">
                        <label for="analytical"> {{data.analyticsLabel}}</label>
                    </span>
                    <br>
                    <span>
                        <input type="checkbox" id="marketing" name="marketing" v-model="marketingValue" v-bind:id="'marketingValue'">
                        <label for="marketing"> {{data.marketingLabel}}</label>
                    </span>
                </div>
                <Buttons :data="buttons" />
            </div>
        </div>
    </div>
</template>
    
<script>
    export default {
        name: 'CSCookieBanner',
        props: {
            data: {
                type: Object,
                default: () => {},
            },
        },
        components: {
            ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
            Buttons: () => import("../components/CSUtilsButtons.vue"),
        },
        data() {
            return {
                show:true,
                customize: false,
                functionalValue: true,
                analyticsValue: true,
                marketingValue: true,
            };
        },
        computed: {
            buttons() {
                let btns = [{
                    buttonText:this.data.acceptAllLabel,
                    action:this.acceptAllFunction
                }]

                if(!this.customize){
                    btns.push({
                        buttonText:this.data.customizeLabel,
                        action:this.showCustomize
                    })
                }else{
                    btns.push({
                        buttonText:this.data.acceptLabel,
                        action:this.acceptFunction
                    })
                } 

                return btns
            }
        },
        methods: {
            acceptFunction() {
                this.cookies().set(this.functionalValue, this.analyticsValue, this.marketingValue)
            },
            acceptAllFunction() {
                this.cookies().set(true, true, true)
            },
            showCustomize() {
                this.customize = true
            }
        },
        mounted(){
            if(typeof window != 'undefined'){
                this.show = !this.cookies().display();
            }
        }
    };
</script>

<style scoped lang="scss">
    .action-area {
        display: flex;
        .text-content {
            width: 75%;
            padding-right: 25px;
        }

        .button-area {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .cs-checkbox span {
                display: flex;
                input {
                    margin-right: 15px;
                }
            }
        }
    }
</style>
